import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout";

import logo from '../img/logo.png'

export default class BlogContents extends Component {

  constructor(props) {
    super(props)

    this.state = {
      videoShow: false
    }

    if (typeof document !== 'undefined' || typeof window !== 'undefined') {
      this.viewSupported = window.innerWidth > 900
    }
  }

  getYoutubeURLId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }


  componentDidMount() {
    try {
      if (this.props.pageContext.videoembed.includes("www.youtube.com")) {
        this.setState({
          videoShow: true
        })

      }
    } catch (err) {

    }
  }

  embedVideo() {
    // try {
    //   return ()
    // } catch (err) {
    //   return ""
    // }
  }

  urlExists(url, callback) {
    fetch(url, { method: 'head' })
      .then(function (status) {
        callback(status.ok)
      });
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>
            {`${decodeURI(this.props.pageContext.blogsName)} | Globelynx | Blogs`}{" "}
          </title>
          <meta
            name="og:title"
            content={`${decodeURI(this.props.pageContext.blogsName)} | Globelynx | Blogs`}
          />
          <meta
            name="description"
            content={`${decodeURI(this.props.pageContext.subText)} | Globelynx | Blogs`} />

          <meta
            property="og:image"
            content={this.props.pageContext.headerImage}
          />
        </Helmet>

        <div className="is-fullwidth">
          <div className="container-fluid ">
            <div className="row has-padding">

              <h1 style={{ textAlign: 'center' }}>{decodeURI(this.props.pageContext.blogsName)}</h1>

              <div className="featured-thumbnail" style={{ textAlign: 'center' }}>
                {
                  !this.state.videoShow && <img className="featured-profile-image" src={this.props.pageContext.headerImage} style={{ objectFit: 'contain', width: '100%', marginBottom: 50, maxWidth: 800 }} onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = logo;
                    e.target.style.objectFit = 'scale-down';
                    e.target.style.background = '#4e5863';
                    e.target.style.display = this.state.videoShow ? 'none' : 'block';
                  }} />
                }

                {
                  this.state.videoShow &&
                  <iframe id="iframe_vide" width={`${this.viewSupported ? '800' : '100%'}`} height="500" src={`https://www.youtube.com/embed/${this.getYoutubeURLId(this.props.pageContext.videoembed)}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
              </div>
              <hr style={{ width: 150 }} />
              <div className="twelve columns">
                <div dangerouslySetInnerHTML={{ __html: this.props.pageContext.blogsContent }} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
